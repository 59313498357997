import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Toolbar, Tooltip, IconButton, Typography, OutlinedInput, InputAdornment, Box, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { useState } from 'react';
// component
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  // justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
};

export default function UserListToolbar({ numSelected, filterName, onFilterName, search, filter, setFilterValue, filterValue}) {

  const [show, setShow] = useState(false)

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <SearchStyle
        sx={{display: search ? 'initial' : 'none'}}
          value={filterName}
          onChange={onFilterName}
          placeholder="Search user..."
          startAdornment={
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          }
        />
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <Iconify icon="eva:trash-2-fill" />
          </IconButton>
        </Tooltip>
      ) : (
       filter  && <Tooltip title="Filter">
          <IconButton onClick={()=> setShow(!show) } >
            <Iconify icon="ic:round-filter-list" />
          </IconButton>
        </Tooltip>
      )}
      {show && <Box style={{marginLeft: '30px'}} className='goIn' >
      <FormControl sx={{width: '180px'}} >
      <InputLabel id="demo-simple-select-label">Filter</InputLabel>
      <Select
          labelId="viewd"
          id="viewd"
          value={filterValue}
          placeholder="Age"
          onChange={({target})=> setFilterValue(target.value)}
          >
          <MenuItem value={''}>Filter view status</MenuItem>
          <MenuItem value={'yes'}>Viewed</MenuItem>
          <MenuItem value={'no'}>Not Viewed</MenuItem>
        </Select>
          </FormControl>
      </Box>}
    </RootStyle>
  );
}
