// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'users',
    path: '/dashboard/users',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'characters',
    path: '/dashboard/characters',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'reports',
    path: '/dashboard/reports',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'company info',
    path: '/dashboard/co-info',
    icon: getIcon('eva:info-outline'),
  },
];

export default navConfig;
