import * as Yup from 'yup';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from '@mui/material';
// components
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import toast, { Toaster } from 'react-hot-toast';
import { DeleteRequest } from '../api/DeleteRequest';
import { PutRequest } from '../api/PutRequest';
import { PostRequest } from '../api/PostRequest';
import { GetRequest } from '../api/GetRequest';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';
// mock

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'from', label: 'Report From', alignRight: false },
  { id: 'to', label: 'Reported On', alignRight: false },
  { id: 'viewed', label: 'Viewed', alignRight: false },
  { id: 'rep', label: 'Replies', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Charecters() {
  const [page, setPage] = useState(0);
  const [Err, setErr] = useState('');

  const [From, setFrom] = useState({});
  const [To, setTo] = useState({});
  const [Chat, setChat] = useState([]);
  
  const [total, setTotal] = useState(0);

  const [chatTotal, setChatTotal] = useState(0)
  const [chatPage, setChatPage] = useState(0)

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [id, setId] = useState(null);

  const [chatLoading, setChatLoading] = useState(true);
  const [openDel, setOpenDel] = useState(false);
  const [open, setOpen] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  
  const [order, setOrder] = useState('asc');
  const [Target, setTarget] = useState('');

  const [Data, setData] = useState([]);

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');


  const Schema = Yup.object().shape({
    name: Yup.string().required('name is required'),
    opposite: id ?Yup.string() :Yup.string().required('opposite is required'),
  });

  const defaultValues = {
    name: '',
    opposite: '',
  };

  const methods = useForm({
    resolver: yupResolver(Schema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    setValue,
    formState: { isSubmitting },
  } = methods;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = Data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleRowsChange = (e) => {
    setPage(0)
    setRowsPerPage(e.target.value)
  }
  const handleChangePage = (event, newPage) => {
    // newPage = page Index (starts from 0)
    // request new page data 
    window.scrollTo(0,0);
    setPage(newPage);
  };


  
  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  
  const getChat = async () => {
    setChatLoading(true);
    try {        
        const res = await GetRequest('report_details', [
          ['pageSize', rowsPerPage],
          ['pageNumber', chatPage+1]
        ], id);
        const res1 = await PutRequest('view_report', id, {viewed: true});
        if(res1.data.success){
          const _data = Data.map((d) => d._id === id ? {...d, viewed: true} : d );
          setData(_data);
        }
        setChatTotal(Math.ceil(res.data.count/10));
        setChat((pre) => ([...pre,...res.data.replies]));
        setErr(null);
        setChatLoading(false);
        // setTotal(res.data.count)
      } catch (error) {
        setChatLoading(false);
        setErr("No Records Founds !");
    }
  }

  const fetchData = async () => {
    try {        
        let stat = '';
        if(filterValue) { stat = filterValue === 'yes' ? Boolean(true) : Boolean(false) };
        const res = await GetRequest('get_reports', [
          ['pageSize', rowsPerPage],
          ['pageNumber', page+1],
          ['viewed', stat]
        ]);
        setData(res.data.data);
        setTotal(res.data.count)
    } catch (error) {
        console.log(error);
    }
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - total) : 0;

  const filteredUsers = applySortFilter(Data, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;
  
  const notify = (msg) => toast.success(msg);
  const notifyErr = (msg) => toast.error(msg);
  const handleClose = () => {
      setValue('name', '')
      setValue('opposite', '')
      setOpen(false);
      setId(null);
  }
  const onSubmit = async (data) => {
    
    const res = id ? await PutRequest('edit_delete_charecter',id, {name: data.name}) :await PostRequest('add_charecter', data);
    if(res?.data?.success){
        fetchData();
        notify(res.data.message)
        setOpen(false);
        setId(null);
        setValue('name', '')
        setValue('opposite', '')
        reset();
      }else{
        notifyErr(res.message || 'Something Went Wrong !')
      }
      
    }
    const handleDelete = async () => {
      let _id; let active;
      if(Target === 'from'){
        _id = From._id;
        active = !From.active
      }else{
        _id = To._id;
        active = !To.active
      }
      const res = await PutRequest('ch_user_status',_id, {active});
      if(res?.data?.success){
        fetchData();
        notify(res.data.message)
        setOpenDel(false);
        setOpen(false);
        setId(null)
    }else{
      notifyErr(res.message || 'Something Went Wrong !');
    }
  }
  const nextChat = () => {
    if(chatPage < chatTotal){
      setChatPage((p) => p+1);
    }
  }
  useEffect(() => {

    fetchData();
  
  }, [page,rowsPerPage])
  useEffect(() => {
    if(open) getChat();
    if(!open) {setChatPage(0); setChat([])}
  }, [open, chatPage])
  useEffect(() => { setPage(0); fetchData(); }, [filterValue])

  return (
    <Page title="Charecters">
      <Container>
        <Toaster
          position="top-center"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            // Define default options
            className: '',
            duration: 4000,
            style: {background: '#363636',color: '#fff'},
            // Default options for specific types
            success: {
              duration: 4000,
              theme: {primary: 'green',secondary: 'black'},
            },
          }}
        />
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Reports
          </Typography>
          {/* <Button variant="contained" id='add-char' sx={{background: '#006adf'}} component={RouterLink} onClick={()=> setOpen(true)} to="#" startIcon={<Iconify icon="eva:plus-fill" />}>
            Add Charecter
          </Button> */}
        </Stack>

        <Card>
          <UserListToolbar filter setFilterValue={setFilterValue} filterValue={filterValue} />

          <Scrollbar>
            <TableContainer  sx={{ minWidth: 800 }}>
              <Table >
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={Data.length}
                  // numSelected={selected.length}
                  // onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                />
                <TableBody >
                  {Data.map((row) => {
                    const { _id, reportedBy , reportedOn, viewed} = row;

                    return (
                      <TableRow
                        hover
                        key={_id}
                        tabIndex={-1}

                      >
                        <TableCell component="th" scope="row">
                              {reportedBy.name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                              {reportedOn.name}
                        </TableCell>
                        <TableCell component="th" scope="row" style={{color:viewed ? "green" :"red" }} >
                              {viewed ? 'Yes' : 'No'}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Iconify icon="eva:message-circle-outline" width={27} height={27} sx={{cursor: 'pointer'}} onClick={()=> {setFrom(reportedBy);setTo(reportedOn); setId(_id);setOpen(true); } } />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onRowsPerPageChange={handleRowsChange}
            onPageChange={handleChangePage}
          />
        </Card>
        {/* MODALS */}
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth id="chat-dialog" >
            <DialogTitle >Replies</DialogTitle>
            <DialogContent >
                  {
                    chatLoading ? 
                    <div style={{width: '100%', display: 'grid', placeItems: 'center', margin: '20px 0 5px'}} >
                      <CircularProgress /> 
                      <div style={{marginTop: "10px"}} >Loading Replies...</div>
                    </div>
                    : 
                    <div className='chat-box' >{
                    Chat.map((ch, k) => <div key={k} className={`${ch.addedBy === To._id ? 'msgTo' : 'msgFrom'} msg`} >
                        <div style={{display: "flex", alignItems: "center"}} >
                          <Iconify icon="eva:people-fill" width={17} height={17} sx={{marginRight: "5px", marginTop: "-2px"}} />
                              {ch.addedBy === To._id ? To.name : From.name}
                          </div>
                        <div>{ch.message}</div>
                      </div>)
                    }
                      {chatPage+1 < chatTotal ? <div style={{display: 'flex', justifyContent: 'center'}} >
                        <button onClick={() => nextChat()} style={{background: "#eeeded", borderRadius: '10px', padding: '3px 20px', cursor: 'pointer'}} >View More...</button>
                      </div>: ''}
                    </div>
                  }
                  {
                    !chatLoading && Err && <Typography variant='h6'  >{Err}</Typography>
                  }
            </DialogContent>
            <DialogActions>
                <Button  onClick={handleClose} color='error' >Cancel</Button>
                <Button type='button' id='modal-add-btn' sx={{background: '#006adf', color: 'white'}} onClick={()=> {setOpenDel(true); setTarget('from')}}  >{From.active ? 'Block' :'Unblock'} {From?.name}</Button>
            <Button type='button' id='modal-add-btn' sx={{background: '#006adf', color: 'white'}} onClick={()=> {setOpenDel(true); setTarget('to')}}  >{To.active ? 'Block' :'Unblock'} {To?.name}</Button>
            </DialogActions>
        </Dialog>
        <Dialog open={openDel} onClose={()=> {setOpenDel(false); setId(null);}} maxWidth="sm" fullWidth id="block-dialog" >
            <DialogTitle >Block User</DialogTitle>
            <DialogContent >
                <Typography >Are you sure want to {Target === 'from' ? From?.active ? 'Block': 'Unblock' :To?.active ? 'Block' :'Unblock'} this user ({Target === 'from' ? From?.name:To?.name})?</Typography>
            </DialogContent>
            <DialogActions>
                <Button  onClick={()=> {setOpenDel(false); setId(null)}}>Cancel</Button>
                <Button id="modal-del-btn" sx={{background: '#ff0000d6', color: 'white'}}   onClick={()=> handleDelete()} >{Target === 'from' ? From?.active ? 'Block': 'Unblock' :To?.active ? 'Block' :'Unblock'}</Button>
            </DialogActions>
        </Dialog>
      </Container>
    </Page>
  );
}
