import {create} from 'zustand';

export const useStore = create((set) => ({
  state: {
    isLoggedIn: false,
  },
  actions: {
    // passed param       prev state
    logIn: () =>         set({ isLoggedIn: true }),
    logOut: () =>        set(() => ({ isLoggedIn: false })),
  },
}));