export const ENDPOINTS = {
    login: 'api/auth/login/admin',
    get_charecters: 'api/character',
    add_charecter: 'api/character',
    edit_delete_charecter: 'api/character', // (PUT || DELETE) & end with id
    get_users: 'api/user', // GET
    stats: 'api/stats', // GET
    stats2: 'api/stats/daily', // GET
    ch_user_status: 'api/user/active-status', // (PUT) & end with id
    get_reports: "api/report", // GET
    report_details: "api/report", // (GET) & end with report id
    view_report: "api/report", // (PUT) & end with report id,
    policy: "api/policy", // GET & Post with param
    terms: "api/term", // GET & Post with param
    get_contact: "api/contact-us", // GET
    edit_contact: "api/contact-us" // POST
}