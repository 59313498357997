import API from './axios';
import { ENDPOINTS } from './endpoints';


export const PostRequest = async (url,payload) => {
    try {
        const res = await API.post(ENDPOINTS[url], payload);
        return res;
    } catch (error) {
        return error;
    }

}