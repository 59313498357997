import API from './axios';
import { ENDPOINTS } from './endpoints';


export const DeleteRequest = async (url,id) => {
    try {
        const res = await API.delete(`${ENDPOINTS[url]}/${id || ''}`);
        return res;
    } catch (error) {
        return error;
    }

}