import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
// component
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

export default function UserMoreMenu({editFunc, delFunc, customFunc, customIco,customLbl}) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem id="popup-delete" sx={{ color: 'text.secondary', display: delFunc ? 'flex' : 'none' }} onClick={() => { setIsOpen(false); delFunc()}} >
          <ListItemIcon>
            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem id="popup-edit" component={RouterLink} to="#" sx={{ color: 'text.secondary' , display: editFunc ? 'flex' : 'none'  }} onClick={() => { setIsOpen(false); editFunc()}} >
          <ListItemIcon>
            <Iconify icon="eva:edit-fill" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem id="popup-custom" component={RouterLink} to="#" sx={{ color: 'text.secondary', display: customFunc ? 'flex' : 'none' }} onClick={() => { setIsOpen(false); customFunc()}} >
          <ListItemIcon>
            <Iconify icon={`eva:${customIco}`} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary={customLbl} primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}
