import API from './axios';
import { ENDPOINTS } from './endpoints';


export const PutRequest = async (url,id,payload) => {
    try {
        const res = await API.put(`${ENDPOINTS[url]}/${id}`, payload);
        return res;
    } catch (error) {
        return error;
    }

}