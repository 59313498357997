import API from './axios';
import { ENDPOINTS } from './endpoints';
 
const makeQuery = (queries) => {
    if(queries.length){
        let q = '?';
        queries.forEach((query, i) => {
            q += `${query[0]}=${query[1]}`;
            if( (queries.length>1) && i<queries.length-1){
                q += "&"
            }
        });
    return q;
    }
}

export const GetRequest = async (url, query, endID) => {
    const _query = query ? makeQuery(query) : '';
    try {
        const res = await API.get(`${ENDPOINTS[url]}${endID? `/${endID}` :''}${_query||''}`);
        return res;
    } catch (error) {
        return error;
    }
       
}