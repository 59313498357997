import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography, Box } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
// sections
import { LoginForm } from '../sections/auth/login';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  return (
    <Page title="Login">
      <RootStyle>
        {mdUp && (
          <SectionStyle>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Box sx={{ ml: 2, transform: 'scale(.5)' }} component="img" src="/static/frienk.png" />
            </Box>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }} textAlign={'center'}>
              Hi, Welcome Back
            </Typography>
            <img src="/static/illustrations/illustration_login.png" alt="login" />
            <Box sx={{ marginTop: '45%' }}>
              <Typography variant="body2" textAlign={'center'} sx={{ fontWeight: '500' }}>
                <em>Powered By</em>: &nbsp;{' '}
                <a
                  style={{ textDecoration: 'none' }}
                  href="https://cnepho.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  cNepho
                </a>
              </Typography>
            </Box>
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            {!mdUp && <Box sx={{ ml: 2, transform: 'scale(.6)' }} component="img" src="/static/frienk.png" />}
            <Typography variant="h4" gutterBottom textAlign={'center'}>
              Admin Sign in
            </Typography>

            <LoginForm />

            {!smUp && (
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                Don’t have an account?{' '}
                <Link variant="subtitle2" component={RouterLink} to="/register">
                  Get started
                </Link>
              </Typography>
            )}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
